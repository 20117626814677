var generic = generic || {};
var Model = Model || {};
var site = site || {};
var pageData = page_data || {};
var google = google || null;
var Mustache = Mustache || {};
var prodcat = prodcat || {};

(function () {
  var bopis_enabled = Drupal.settings.globals_variables.bopis_enabled;
  var sppTranslations = (site.translations || {}).product || {};
  var sppBopisTranslations = ((site.templates || {}).bopis_v1 || {}).data || {};
  var checkoutTranslations = (((pageData.data || {}).rb || {}).language || {}).bopis || {};
  var skuData = {};
  // As we plan to use this module on SPP and checkout pages we can create a map for translations from both pages
  var bopisTranslations = {
    today:
      sppTranslations.today || checkoutTranslations.today || sppBopisTranslations.today || 'Today',
    tomorrow:
      sppTranslations.tomorrow ||
      checkoutTranslations.tomorrow ||
      sppBopisTranslations.tomorrow ||
      'Tomorrow',
    am: sppTranslations.AM || checkoutTranslations.AM || sppBopisTranslations.AM || 'AM',
    pm: sppTranslations.PM || checkoutTranslations.PM || sppBopisTranslations.PM || 'PM',
    preposition:
      sppTranslations.preposition ||
      checkoutTranslations.preposition ||
      sppBopisTranslations.preposition ||
      'at',
    bopis: sppBopisTranslations
  };
  /**
   * Singleton data model that handles collecting door inventory data for a single or set of skus.
   * When you pass a sku, or set of skus, with the door query, a door_inventory data object will be included.
   * On success, the event 'BopisInventory:query:success' is fired and other panels can listen in on that event.
   */
  var bopisInventory = (function () {
    // Default Constants
    var DEFAULTS = {
      RADIUS:
        (Drupal.settings.globals_variables.bopis_default_radius &&
          parseInt(Drupal.settings.globals_variables.bopis_default_radius)) ||
        200,
      USE_BROWSER_LOCATION:
        Drupal.settings.globals_variables.bopis_use_browser_location &&
        navigator.geolocation &&
        navigator.geolocation.getCurrentPosition
    };
    var BRAND_ID = 1;
    var REGION_ID = Drupal.settings.globals_variables.bopis_region_id || 0;
    var API_URL = '/rest/api/v2/bopis/inventory?';
    var IS_INITIALIZED = false;
    var bopisCache = null;
    var bopisLocationApi = null;
    var bopisUtils = null;
    var that = {
      data: null,
      requestParams: null,
      DEFAULTS: DEFAULTS
    };

    that.getLastRequestParams = function () {
      return that.requestParams || bopisCache.getLastRequestParams();
    };

    /**
     * Method used to call the load doors and inventory data query.
     * When a sku, or array of skus, is passed, it will return an inventory data object with the door data.
     * Here is an example of the return data object with 'door_inventory'.
     *   "value":{
     *     "driving_directions":"yes",
     *     "longitude":"-117.9073244",
     *     "count":8,
     *     "country_code":"us",
     *     "latitude":"33.6834142",
     *     "doors":[446387,185639,165341,165395,165411,429482,429489,429376],
     *     "door_inventory": { ..Inventory objects by door.. },
     *     "results":{ ..Result door data.. }
     *   }
     * @param {{radius?: number, skus?: Array | number, zip?: number}} args
     * @returns {Promise<object>} Promise with door_inventory responce or errors array
     */
    that.getInventoryByDoors = function (args) {
      var that = this;
      var _args = args || {};
      var params = {
        radius: _args.radius || DEFAULTS.RADIUS,
        skus: _args.skus,
        zip: _args.zip || null
      };
      var errors = that._validateParams(params);

      if (errors.length > 0) {
        return new Promise(function (resolve, reject) {
          $(document).trigger('BopisInventory:query:failure', {
            errors: errors
          });
          reject(errors);
        });
      }

      return that.query(params);
    };

    /**
     * Method responsible for making fetch to backend with all needed params.
     * If successful, it will return the result along with setting the class var data and local SS cache.
     * @param {{radius: number, skus: Array | number, zip: number | null}} args
     * @returns {Promise<object>} Promise with door_inventory responce
     */
    that.query = function (args) {
      var that = this;
      var params = args || {};
      var oSSData = bopisCache.getSSData(params);
      var _handleInventory = function (result) {
        if (oSSData.doors === null || oSSData.inventory === null) {
          bopisCache.setSSData(params, result);
        }
        that.data = result;
        that.requestParams = params;
        bopisCache.setLastRequestParams(params);

        return result;
      };

      if (oSSData.inventory) {
        return new Promise(function (resolve) {
          resolve(_handleInventory(oSSData.inventory));
        });
      }

      return that
        ._getRequestPayload(params)
        .then(function (payload) {
          return that._sendRequest(payload);
        })
        .then(function (result) {
          var formattedResult = _handleInventory(result);

          $(document).trigger('BopisInventory:query:success', formattedResult);

          return formattedResult;
        })
        .catch(function (e) {
          console.log('bopis inventory failure');
          $(document).trigger('BopisInventory:query:failure', e);

          throw e;
        });
    };

    that.findBestDoor = function (selectedSku) {
      var cacheData = bopisCache.getSSData(that.getLastRequestParams());
      var inventory = that.data || cacheData.inventory || {};
      var bopisDoors = cacheData.bopisDoors || [];
      var lookupSKUs;

      if (!selectedSku) {
        return null;
      }
      lookupSKUs = selectedSku.length ? selectedSku : [selectedSku];

      var bestDoor = null;
      var findId = bopisDoors.find(function (doorId) {
        var doorData = inventory.doors && inventory.doors[doorId];
        var inv = doorData && doorData.door_inventory;

        return !lookupSKUs.find(function (sku) {
          return !((inv && (inv.skus_onhand[sku]
                  || (inv.cart_skus_onhand && inv.cart_skus_onhand[sku])))
              || {}
          ).is_available;
        });
      });

      if (findId) {
        bestDoor = inventory.doors[findId];
      }

      return bestDoor;
    };

    that.getAllAvailableDoorIDs = function (selectedSku) {
      var cacheData = bopisCache.getSSData(that.getLastRequestParams());
      var inventory = that.data || cacheData.inventory || {};
      var bopisDoors = cacheData.bopisDoors || [];

      return bopisDoors.filter(function (doorId) {
        var doorData = inventory.doors[doorId];

        return (
          (doorData.door_inventory && doorData.door_inventory.skus_onhand[selectedSku]) ||
          {}
        ).is_available;
      });
    };

    that.getSelectedStore = function () {
      var data = that.data || bopisCache.getSSData().inventory || {};

      return data && data.bopis_store_number;
    };

    that.getDoorsInfo = function () {
      var params = that.getLastRequestParams();
      var SSdata = bopisCache.getSSData(params);
      var doorsInfo = that.data && that.data.doors ? that.data.doors : SSdata.inventory.doors || {};
      var bopisDoors = SSdata.bopisDoors || [];
      var doorDistance = bopisCache.getStoresDistance(params);

      if (doorsInfo) {
        $.each(doorsInfo, function (key, door) {
          if (!door.info.DISTANCE && doorDistance[key]) {
            door.info.DISTANCE = parseFloat(doorDistance[key]).toFixed(2);
          }
        });
      }

      return {
        doors: bopisDoors,
        info: doorsInfo
      };
    };

    that.hasDoorsNearby = function (deliveryMethod) {
      var params = that.getLastRequestParams();
      var inventory = that.data || bopisCache.getSSData(params).inventory || {};
      var activeDoors =
        inventory.omni_doors_status[deliveryMethod] &&
        Array.isArray(inventory.omni_doors_status[deliveryMethod].active)
          ? inventory.omni_doors_status[deliveryMethod].active
          : [];

      return activeDoors.length;
    };

    that.getBopisOrDeliveryAvailability = function (productsSku, deliveryMethod) {
      var params = that.getLastRequestParams();
      var inventory = that.data || bopisCache.getSSData(params).inventory || {};
      var activeDoors =
        inventory.omni_doors_status[deliveryMethod] &&
        Array.isArray(inventory.omni_doors_status[deliveryMethod].active)
          ? inventory.omni_doors_status[deliveryMethod].active
          : [];
      var skusAvailability = {};

      activeDoors.forEach(function (doorId) {
        var door = inventory.doors[doorId].door_inventory;
        var availabilityObj = {};

        /* There are 3 possible types of availabilityObj: 
         {0: n} - all sku unavailable;
         {1: n} - all sku available;
         {0: n; 1: m} - partial sku available; */
        productsSku.forEach(function (sku, idx) {
          var inv = sku && door.skus_onhand[sku];
          var cartInv = sku && door.cart_skus_onhand && door.cart_skus_onhand[sku];
          var isAvailable = (inv && inv.is_available) || (cartInv && cartInv.is_available);

          availabilityObj[isAvailable] = idx;
        });
        skusAvailability[doorId] = availabilityObj;
      });

      return skusAvailability;
    };

    that.canDeliverToday = function (storeHours, offset) {
      var hours = bopisUtils.getStoreHours(storeHours, offset);

      if (
        hours.closingHours > hours.currentHoursWithBuffer ||
        (hours.closingHours === hours.currentHoursWithBuffer &&
          hours.closingMinutes > hours.currentMinutesWithBuffer)
      ) {
        return true;
      }

      return false;
    };

    that.isAvailableNextBusinessDay = function (storeHours) {
      var todayDate = new Date();
      var todayNumber = todayDate.getDay();
      var nextDayNumber = todayNumber < 6 ? todayNumber + 1 : 0;
      var hours = storeHours.raw[nextDayNumber];

      if (hours.is_closed || (hours.close === '0000' && hours.open === '0000')) {
        return true;
      }
    };

    that.checkBopisOrDeliveryAvailabilty = function (productsSku, deliveryMethod, allAvailable) {
      var availabilityObj = that.getBopisOrDeliveryAvailability(productsSku, deliveryMethod);
      var doorIds = Object.keys(availabilityObj);
      var i = 0;
      var door;
      var doorAvailability;

      for (; i < doorIds.length; i++) {
        door = doorIds[i];
        doorAvailability = availabilityObj[door];
        if (allAvailable) {
          if (
            typeof doorAvailability[0] === 'undefined' &&
            typeof doorAvailability[1] !== 'undefined'
          ) {
            return true;
          }
        } else if (typeof doorAvailability[1] !== 'undefined') {
          return true;
        }
      }

      return false;
    };

    /**
     * Returns the payload for the inventory request
     *
     * @param {{radius: number, skus: Array | number, zip: number | null}} params
     * @return {Promise<object>}
     */
    that._getRequestPayload = function (params) {
      var sDoors = bopisCache.getSSData(params).doors;
      var payload = {
        brand: BRAND_ID,
        region: REGION_ID,
        mode: DEFAULTS.USE_BROWSER_LOCATION ? 'coords' : 'use_header',
        radius: params.radius,
        sku_ids: _.isArray(params.skus) ? params.skus.join(',') : params.skus
      };

      if (Drupal.settings.globals_variables.bopis_uom) {
        payload = Object.assign(payload, { uom: Drupal.settings.globals_variables.bopis_uom });
      }

      if (Drupal.settings.globals_variables.two_hours_delivery) {
        payload = Object.assign(payload, { courier: 1 });
      }

      if (params.zip) {
        payload = Object.assign(payload, {
          zip: params.zip,
          mode: 'address'
        });
      }

      return new Promise(function (resolve, reject) {
        var doorsParam = _.isArray(sDoors) ? sDoors.join(',') : sDoors;

        if (doorsParam) {
          payload = Object.assign(payload, {
            mode: 'door_id',
            door_ids: doorsParam
          });
        } else if (sDoors !== null && !sDoors.length && payload.mode !== 'address') {
          $(document).trigger('BopisInventory:query:noDoors', null);
          reject('BopisInventory - no doors');
        }

        if (payload.mode === 'coords') {
          bopisLocationApi
            .getUserLocation()
            .then(function (userCoords) {
              payload = Object.assign(payload, {
                latitude: userCoords.latitude,
                longitude: userCoords.longitude
              });
              resolve(payload);
            })
            .catch(function () {
              payload = Object.assign(payload, { mode: 'use_header' });
              resolve(payload);
            });
        } else {
          resolve(payload);
        }
      });
    };

    /**
     * Method for validation the requset params
     *
     * @param {{radius: number, skus: Array | number, zip: number | null}} params
     * @return {Promise<object>}
     */
    that._validateParams = function (params) {
      var errors = [];
      var _zip = params.zip;
      var _skus = params.skus;

      if (_zip) {
        // If zip is entered, check for valid zip.
        var zipRegEx = bopisUtils.getLocalisedZipRegEx();

        if (!zipRegEx.test(_zip)) {
          errors.push({ key: 'err_invalid_zip' });
        }
      }

      if (!_skus) {
        errors.push({ key: 'err_invalid_skus' });
      }

      return errors;
    };
    /**
     * Method for sending the API request
     *
     * @param {{
        brand: number,
        region: number,
        mode: 'coords' | 'use_header' | 'address' | 'door_id',
        radius: number,
        sku_ids: string,
        zip?: number,
        courier?: 0 | 1,
        door_ids?: Array<number>,
        latitude?: number,
        longitude?: number
      }} payload
    * @return {Promise<object>} 
    */
    that._sendRequest = function (payload) {
      var bopisInvWithCart = Drupal.settings.globals_variables.bopis_inventory_with_cart;

      if (bopisInvWithCart) {
        payload.include_cart = 1;
        payload.include_store_selection = 1;
        payload.include_bopis_or_courier_selection = 1;
      }

      return $.ajax({
        url: API_URL + $.param(payload),
        contentType: 'application/json',
        dataType: 'json'
      });
    };

    return {
      init: function (bopisCacheService, bopisLocationApiService, bopisUtilsService) {
        if (!IS_INITIALIZED) {
          bopisCache = bopisCacheService;
          bopisLocationApi = bopisLocationApiService;
          bopisUtils = bopisUtilsService;
          IS_INITIALIZED = true;
        }
      },
      getInstance: function () {
        return that;
      }
    };
  })();
  /**
   * Singleton model that handles doors inventory caching to the local storage.
   *
   */
  var bopisCache = (function () {
    var CACHE_LIFETIME =
      (Drupal.settings.globals_variables.bopis_cache_lifetime &&
        parseInt(Drupal.settings.globals_variables.bopis_cache_lifetime)) ||
      15 * 60 * 1000;
    var SS_KEYS = {
      DOORS: 'doors_cache',
      BOPIS_DOORS: 'bopis_doors_cache',
      COURIER_DOORS: 'courier_doors_cache',
      DISTANCE: 'distance',
      COURIER_SELECTED_STORE: 'courier_selected_store',
      INVENTORY: '+inventory',
      USER_ZIP_CODE: 'user_zip_code',
      DATE_TIME: 'cache_dateTime',
      LAST_PARAMS: 'last_params'
    };
    var SS_NAMESPACE = 'BOPIS+';
    var that = {};

    /**
     * Getter for ss bopis doors key
     *
     * @param {{radius: number, zip: number | null}} args
     * @return {string}
     */
    that.getSSDoorsKey = function (args) {
      return SS_NAMESPACE + (args?.zip || 'no-zip') + '+' + args?.radius || 0 + '+' + SS_KEYS.DOORS;
    };

    /**
     * Getter for ss bopis doors key
     *
     * @param {{radius: number, zip: number | null}} args
     * @return {string}
     */
    that.getSSBopisDoorsKey = function (args) {
      return (
        (args && SS_NAMESPACE + (args?.zip || 'no-zip') + '+' + args?.radius + '+' + SS_KEYS.BOPIS_DOORS) || 0 + '+' + SS_KEYS.BOPIS_DOORS
      );
    };

    /**
     * Getter for ss courier doors key
     *
     * @param {{radius: number, zip: number | null}} args
     * @return {string}
     */
    that.getSSCourierDoorsKey = function (args) {
      return (
        (args && SS_NAMESPACE + (args?.zip || 'no-zip') + '+' + args?.radius + '+' + SS_KEYS.COURIER_DOORS) ||
        0 + '+' + SS_KEYS.COURIER_DOORS
      );
    };

    /**
     * Getter for ss distance key
     *
     * @param {{radius: number, zip: number | null}} args
     * @return {string}
     */
    that.getSSDistanceKey = function (args) {
      return (
        (args && SS_NAMESPACE + (args?.zip || 'no-zip') + '+' + args?.radius + '+' + SS_KEYS.DISTANCE) || 0 + '+' + SS_KEYS.DISTANCE
      );
    };

    /**
     * Getter for ss distance key
     *
     * @param {{radius: number, skus: Array | number, zip: number | null}} args
     * @return {string}
     */
    that.getSSInvKey = function (args) {
      var skusStr = '';

      if (Boolean(args)) {
        skusStr = _.isArray(args.skus) ? args.skus.join('+') : args.skus;
      }
      return that.getSSDoorsKey(args) + SS_KEYS.INVENTORY + skusStr;
    };

    /**
     * Getter for ss distance key
     *
     * @return {string}
     */
    that.getSSDateTimeKey = function () {
      return SS_NAMESPACE + SS_KEYS.DATE_TIME;
    };

    /**
     * Getter for ss selected courier store key
     *
     * @return {string}
     */
    that.getSSCourierSelectedStoreKey = function () {
      return SS_NAMESPACE + SS_KEYS.COURIER_SELECTED_STORE;
    };

    /**
     * Getter for ss selected uzer zip code key
     *
     * @return {string}
     */
    that.getSSUserZipCodeKey = function () {
      return SS_NAMESPACE + SS_KEYS.USER_ZIP_CODE;
    };
    /**
     * Getter for ss last inventory api request params key
     *
     * @return {string}
     */
    that.getSSLastRequestParamsKey = function () {
      return SS_NAMESPACE + SS_KEYS.LAST_PARAMS;
    };

    /**
     * Return if the cache is still active or not
     * if is expired it delete the default cache
     *
     * @return {boolean}
     */
    that.isSSCacheActive = function () {
      var cacheDateTime = parseInt(sessionStorage.getItem(that.getSSDateTimeKey()));

      if (Date.now() - cacheDateTime > CACHE_LIFETIME) {
        that.clearSSStorage();
        return false;
      }

      return true;
    };

    /**
     * Delete bopis cache
     * @return {void}
    */
    that.clearSSStorage = function () {
      var n = sessionStorage.length;
      var key;

      while (n--) {
        key = sessionStorage.key(n);
        if ((/BOPIS+/).test(key)) {
          sessionStorage.removeItem(key);
        }
      }
    };

    /**
     * Return doors and inventory data from cache
     *
     * @param {{radius: number, skus: Array | number, zip: number | null}} args
     * @return {{doors: string, distance: string, inventory: string}}}
     */
    that.getSSData = function (args) {
      var ssParams = args && site && site.Bopis && Object.assign(args, {skus: site.Bopis.inventorySkus()}) || args;
      var doorKey = args && that.getSSDoorsKey(ssParams);
      var bopisDoorKey = args && that.getSSBopisDoorsKey(ssParams);
      var courierDoorKey = args && that.getSSCourierDoorsKey(ssParams);
      var distanceKey = args && that.getSSDistanceKey(args);
      var invKey = args && that.getSSInvKey(args);

      if (!that.isSSCacheActive() || !args) {
        return {
          doors: null,
          bopisDoors: null,
          courierDoors: null,
          distance: null,
          inventory: null
        };
      }

      return {
        doors: JSON.parse(sessionStorage.getItem(doorKey)),
        bopisDoors: JSON.parse(sessionStorage.getItem(bopisDoorKey)),
        courierDoors: JSON.parse(sessionStorage.getItem(courierDoorKey)),
        distance: JSON.parse(sessionStorage.getItem(distanceKey)),
        inventory: JSON.parse(sessionStorage.getItem(invKey))
      };
    };

    /**
     * Set doors and inventory data to the session storage
     *
     * @param {{radius: number, skus: Array | number, zip: number | null}} args
     * @param {object} result
     */
    that.setSSData = function (args, result) {
      var ssParams = site && site.Bopis && Object.assign(args, {skus: site.Bopis.inventorySkus()}) || args;
      var doorKey = that.getSSDoorsKey(ssParams);
      var bopisDoorKey = that.getSSBopisDoorsKey(ssParams);
      var courierDoorKey = that.getSSCourierDoorsKey(ssParams);
      var invKey = that.getSSInvKey(ssParams);
      var storesNotSaved = !sessionStorage.getItem(doorKey);
      var sDoors = (storesNotSaved && Object.keys(result.doors || {})) || [];
      var sBopisDoors =
        storesNotSaved && Array.isArray(result.omni_doors_status.bopis.active)
          ? result.omni_doors_status.bopis.active
          : [];
      var sCourierDoors =
        storesNotSaved && Array.isArray((result.omni_doors_status.courier_delivery || {}).active)
          ? result.omni_doors_status.courier_delivery.active
          : [];
      var doors = result.doors || {};
      var distance = {};

      if (storesNotSaved) {
        sessionStorage.setItem(that.getSSDateTimeKey(), Date.now());
        sessionStorage.setItem(doorKey, JSON.stringify(sDoors));
        sessionStorage.setItem(bopisDoorKey, JSON.stringify(sBopisDoors));
        sessionStorage.setItem(courierDoorKey, JSON.stringify(sCourierDoors));
        doors &&
          $.each(doors, function (id, data) {
            distance[id] = data.info.DISTANCE;
          });
        sessionStorage.setItem(that.getSSDistanceKey(args), JSON.stringify(distance));
      }
      sessionStorage.setItem(invKey, JSON.stringify(result));
    };

    /**
     * Set the selected store in the session storage for later usage
     * @return {void} Returns nothing
     */
    that.setSelectedStore = function (door) {
      sessionStorage.setItem(that.getSSCourierSelectedStoreKey(), JSON.stringify(door));
    };

    /**
     * Get the selected stores for courier from the DB
     * @return {object} Door object
     */
    that.getSelectedStore = function () {
      var selectedDoor = sessionStorage.getItem(that.getSSCourierSelectedStoreKey());

      return selectedDoor ? JSON.parse(selectedDoor) : null;
    };

    /**
     * Return object with distances to stores
     *
     * @param {{zip: number, radius: number}} args
     * @return {doorId: number, distance: string}}
     */
    that.getStoresDistance = function (args) {
      var doorsDistance = sessionStorage.getItem(that.getSSDistanceKey(args));

      return JSON.parse(doorsDistance);
    };

    /**
     * Set the last inventory api request params into the session storage for later usage
     * @return {void} Returns nothing
     */
    that.setLastRequestParams = function (params) {
      return sessionStorage.setItem(that.getSSLastRequestParamsKey(), JSON.stringify(params));
    };

    /**
     * Get the last inventory api request params from the DB
     * @return {number} zip
     */
    that.getLastRequestParams = function () {
      var SSData = JSON.parse(sessionStorage.getItem(that.getSSLastRequestParamsKey()));
      var invSkus = site && site.Bopis && site.Bopis.inventorySkus();
      if (SSData && SSData.skus && invSkus && invSkus.length) {
        SSData.skus = site.Bopis.inventorySkus();
      }
      return SSData;
    };

    return {
      getInstance: function () {
        return that;
      }
    };
  })();
  /**
   * Singleton model that handles Postmates delivery.
   *
   */
  var twoHoursDelivery = (function () {
    // Default Constants
    var DEFAULTS = {
      POSTMATES_DROPOFF_OFFSET_NOT_ASAP:
        (Drupal.settings.globals_variables.postmates_dropoff_offset_not_asap &&
          parseInt(Drupal.settings.globals_variables.postmates_dropoff_offset_not_asap)) ||
        2,
      POSTMATES_CUTOFF_TIME_OFFSET:
        (Drupal.settings.globals_variables.postmates_cutoff_time_offset &&
          parseInt(Drupal.settings.globals_variables.postmates_cutoff_time_offset)) ||
        3,
      DROPOFF_OFFSET_ASAP:
        (Drupal.settings.globals_variables.postmates_dropoff_offset_asap &&
          parseInt(Drupal.settings.globals_variables.postmates_dropoff_offset_asap)) ||
        1,
      ORDER_PREP_SLA:
        (Drupal.settings.globals_variables.postmates_order_prep_sla &&
          parseInt(Drupal.settings.globals_variables.postmates_order_prep_sla)) ||
        1,
      PICKUP_OFFSET:
        (Drupal.settings.globals_variables.postmates_pickup_offset &&
          parseInt(Drupal.settings.globals_variables.postmates_pickup_offset)) ||
        0.25,
      MAX_DELIVERY_DISTANCE:
        (Drupal.settings.globals_variables.postmates_max_delivery_distance &&
          parseInt(Drupal.settings.globals_variables.postmates_max_delivery_distance)) ||
        10
    };
    var IS_INITIALIZED = false;
    var ONE_DAY = 24 * 60 * 60 * 1000;
    var bopisCache = null;
    var bopisUtils = null;
    var bopisText = null;
    var bopisInventory = null;
    var that = {};

    /**
     * Check if all skus are available is some of the stores and returns the store ids
     * @param {Object} bopisData
     * @param {Array} skus
     * @return {Array} All store ids where all the products are available
     */
    that.getAvailableStoreIds = function (skus, bopisData) {
      var courierDoors = (bopisData && bopisData.courierDoors) || [];
      var inventory = (bopisData && bopisData.inventory) || {};
      var maxDeliveryDistance = inventory && inventory.config && inventory.config.courier_radius
        || DEFAULTS.MAX_DELIVERY_DISTANCE;

      return (
        courierDoors.filter(function (doorId) {
          return skus.reduce(function (hasAllAvailable, skuId) {
            var door = inventory && inventory.doors && inventory.doors[doorId];

            if (!door.info.DISTANCE) {
              var params = bopisInventory.getLastRequestParams();
              var SSdata = bopisCache.getSSData(params);

              door.info.DISTANCE = SSdata.distance[doorId] || null;
            }
            if (
              !door ||
              door.info.DISTANCE > maxDeliveryDistance
            ) {
              return false;
            }
            if (
              !(
                door.door_inventory.skus_onhand &&
                door.door_inventory.skus_onhand[skuId] &&
                door.door_inventory.skus_onhand[skuId].is_available
              ) &&
              !(
                door.door_inventory.cart_skus_onhand &&
                door.door_inventory.cart_skus_onhand[skuId] &&
                door.door_inventory.cart_skus_onhand[skuId].is_available
              )
            ) {
              return false;
            }

            return hasAllAvailable;
          }, true);
        }) || []
      );
    };
    /**
     * Get the nearest store where all items are available
     * @param {Array} skus
     * @param {Object} bopisData
     * @param {boolean} todayOnly
     * @return {object | null} Returns the data of the newarest store where all items are available
     * if is not available in none of the stores it will return null
     */
    that.getNearestStoreAvailable = function (skus, bopisData, todayOnly) {
      var availableStoreIds = that.getAvailableStoreIds(skus, bopisData);
      var inventory = (bopisData && bopisData.inventory) || {};

      return availableStoreIds.reduce(function (nearestStore, doorId) {
        if (
          inventory.doors[doorId] &&
          (!todayOnly || that.canDeliverToday(inventory.doors[doorId].store_hours)) &&
          (!nearestStore || nearestStore.info.DISTANCE > inventory.doors[doorId].info.DISTANCE)
        ) {
          return inventory.doors[doorId];
        }

        return nearestStore;
      }, null);
    };
    /**
     * Check if it is possible to deliver from the store
     * @param {object} store
     * @return {boolean}
     */
    that.isDeliverable = function (store) {
      return store && store.info.DISTANCE < DEFAULTS.MAX_DELIVERY_DISTANCE;
    };
    /**
     * Check if it is possible to deliver today based on the store open hours
     * @param {object} storeHours
     * @return {boolean}
     */
    that.canDeliverToday = function (storeHours) {
      var hours = bopisUtils.getStoreHours(storeHours, DEFAULTS.POSTMATES_CUTOFF_TIME_OFFSET);

      if (
        hours.closingHours > hours.currentHoursWithBuffer ||
        (hours.closingHours === hours.currentHoursWithBuffer &&
          hours.closingMinutes > hours.currentMinutesWithBuffer)
      ) {
        return true;
      }

      return false;
    };
    /**
     * Returns cut off delivery time in hh:mm format
     * @param {object} storeHours
     * @return {string} String with delivery cut off time
     */
    that.getTodayDeliveryCutOffTime = function (storeHours) {
      var hours = bopisUtils.getStoreHours(storeHours, DEFAULTS.POSTMATES_CUTOFF_TIME_OFFSET);
      var time = new Date();

      time.setHours(hours.closingHours - DEFAULTS.POSTMATES_CUTOFF_TIME_OFFSET);
      time.setMinutes(hours.closingMinutes);
      time = time.toLocaleTimeString(window.navigator.language || 'default', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit'
      });
      if ($.browser.mozilla) {
        return time.toLowerCase();
      }

      return time.replace(/\s+/g, '').replace(/0/, '').toLowerCase();
    };
    /**
     * Generate all the possible windows based on the store hours and current time
     * @param {object}  store  The selected store
     * @param {bool}    tomorrow - Optional - generate store hours for tomorrow
     * @return {array} Array of object with all the possible windows
     */
    that.getAvailableWindows = function (store, tomorrow) {
      var todayDate = new Date();
      var tomorrowDate = new Date(todayDate.getTime() + ONE_DAY);
      var currentHour = tomorrow
        ? 1
        : todayDate.getHours() + parseFloat((todayDate.getMinutes() / 60).toFixed(2));
      var day = tomorrow ? (todayDate.getDay() + 1) % 7 : todayDate.getDay();
      var dayName = !tomorrow
        ? bopisText.today +
          ' (' +
          todayDate.toLocaleString('default', {
            month: 'short',
            day: 'numeric'
          }) +
          ')'
        : bopisText.tomorrow +
          ' (' +
          tomorrowDate.toLocaleString('default', {
            month: 'short',
            day: 'numeric'
          }) +
          ')';
      var workingHours = store.store_hours.raw[day];
      var openingHour = parseInt(workingHours.open.substr(0, 2));
      var closingHour =
        workingHours.close === '0000' ? 24 : parseInt(workingHours.close.substr(0, 2));
      var dropoffWindows = [];
      var asap = tomorrow ? false : true;
      var start;
      var dropoffOffset;
      var closingOffset = 1;
      var dropoffDeadline;
      var dropWindow;

      if (currentHour < openingHour) {
        currentHour = openingHour;
        asap = false;
      }

      start = currentHour + DEFAULTS.ORDER_PREP_SLA;

      while (start + DEFAULTS.ORDER_PREP_SLA < closingHour - closingOffset) {
        dropoffOffset = asap
          ? DEFAULTS.DROPOFF_OFFSET_ASAP
          : DEFAULTS.POSTMATES_DROPOFF_OFFSET_NOT_ASAP;
        dropoffDeadline = start + dropoffOffset;
        if (dropoffDeadline + dropoffOffset > closingHour - closingOffset) {
          dropoffDeadline = closingHour - closingOffset;
        }
        dropWindow = {
          dropoffReady: start,
          dropoffDeadline: dropoffDeadline,
          pickupReady: start,
          pickupDeadline: start + DEFAULTS.PICKUP_OFFSET,
          name: asap
            ? 'ASAP ' + dayName
            : dayName +
              ' ' +
              bopisUtils.formatHour(start) +
              ' - ' +
              bopisUtils.formatHour(dropoffDeadline),
          today: !tomorrow
        };
        dropoffWindows.push(dropWindow);
        start = Math.ceil(dropoffDeadline);
        asap = false;
      }

      return dropoffWindows;
    };
    /**
     * Check if there are any stores with Postmates delivery
     * @return {boolean}
     */
    that.hasPostmatesDoors = function () {
      var bopisData = bopisCache.getSSData(bopisInventory.getLastRequestParams()) || {};
      var postmatesDoors = bopisData.courierDoors || [];
      var inventory = bopisData.inventory || {};

      return Boolean(postmatesDoors.filter(function (doorId) {
        if (!inventory.doors[doorId].info.DISTANCE) {
          inventory.doors[doorId].info.DISTANCE = bopisData.distance[doorId] || null;
        }
        if (
          !inventory.doors ||
          !inventory.doors[doorId] ||
          inventory.doors[doorId].info.DISTANCE > DEFAULTS.MAX_DELIVERY_DISTANCE
        ) {
          return false;
        }

        return true;
      }).length);
    };

    return {
      init: function (
        bopisCacheService,
        bopisUtilsService,
        bopisTranslations,
        bopisInventoryService
      ) {
        if (!IS_INITIALIZED) {
          bopisCache = bopisCacheService;
          bopisUtils = bopisUtilsService;
          bopisText = bopisTranslations;
          bopisInventory = bopisInventoryService;
          IS_INITIALIZED = true;
        }
      },
      getInstance: function () {
        return that;
      }
    };
  })();
  /**
   * Singleton model that handles utils methods for BOPIS and Postmates.
   *
   */
  var bopisUtils = (function () {
    // Default Constants
    var DEFAULTS = {
      ZIP_REGEX:
        Drupal.settings.globals_variables.location_check_zipcode_regex ||
        '(^\\d{5}(-\\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnpstvxy]{1}\\d{1}[A-Za-z]{1} ?\\d{1}[A-Za-z]{1}\\d{1})$'
    };
    var IS_INITIALIZED = false;
    var bopisTemplates =
      site && site.templates && site.templates.bopis_v1
        ? $(site.template.get({ name: 'bopis_v1' }))
        : $('<div>');
    var templateCache = {};
    var bopisText = null;
    var $app = $('.js-bopis-wrapper');
    var that = {
      isMobileView: (site.client || {}).isMobile || $(window).width() < 1024,
      insertTemplate: function () {
        $app.append(bopisTemplates.find('.js-bopis-container'));
      },

      /**
       * Convert hours to ISO format
       * @return {string} ISO date string
       */
      hourToISO: function (val, useCorrection, storeTzOffset, isTomorrow) {
        var date = new Date();
        var tzOffset = parseInt(storeTzOffset || 0);
        var hour = Math.floor(val);
        var minutes = parseInt((val - hour) * 60);

        if (isTomorrow) {
          date.setDate(date.getDate() + 1);
        }

        date.setHours(hour);
        date.setMinutes(minutes);

        // If store timezone is different then user timezone we need a correction
        if (that.isTzDifferent(tzOffset) && useCorrection) {
          date = new Date(date.getTime() + that.getCorrectedTzOffset(tzOffset) * 60000);
        }

        return date.toISOString();
      },
      /**
       * Returns corrected timezone offset
       * @param {number} storeTzOffset
       * @return {number}
       */
      getCorrectedTzOffset: function (storeTzOffset) {
        var jsTzOffset = that.getJsTzOffset(storeTzOffset);
        var userTzOffset = new Date().getTimezoneOffset();

        return jsTzOffset - userTzOffset;
      },

      /**
       * Returns object with calculated store hours
       * @param {object} storeHours
       * @param {number} offSet
       * @return {boolean}
       */
      getStoreHours: function (storeHours, offSet) {
        var today = new Date();
        var todayWithBuffer = new Date(today.setHours(today.getHours() + offSet));
        var currentHoursWithBuffer =
          todayWithBuffer.getHours() < offSet ? 24 : todayWithBuffer.getHours();
        var currentMinutesWithBuffer = todayWithBuffer.getMinutes();
        var workingHours = storeHours.raw[today.getDay()];
        var closingHours = parseInt(workingHours.close.toString().substring(0, 2));
        var closingMinutes = parseInt(workingHours.close.toString().substring(2, 4));

        return {
          currentHoursWithBuffer: currentHoursWithBuffer,
          currentMinutesWithBuffer: currentMinutesWithBuffer,
          closingHours: closingHours,
          closingMinutes: closingMinutes
        };
      },

      /**
       * Check if store TZ offset is different from the user TZ offset
       * @param {number} storeTzOffset
       * @return {number}
       */
      isTzDifferent: function (storeTzOffset) {
        var jsTzOffset = that.getJsTzOffset(storeTzOffset);
        var userTzOffset = new Date().getTimezoneOffset();

        return storeTzOffset && userTzOffset !== jsTzOffset ? true : false;
      },

      getJsTzOffset: function (tzOffset) {
        return (tzOffset - 2 * tzOffset) / 60;
      },
      /**
       * Format hours to 12-hour clock system
       * @param {number} hour
       * @return {string}
       */
      formatHour: function (hour) {
        return hour < 12
          ? hour + bopisText.am
          : hour === 12
            ? '12' + bopisText.pm
            : (hour % 12) + bopisText.pm;
      },
      /**
       * Returns the regex to use for testing the user-entered zip/postcode
       * Should be set in the relevant domains/*.inc file but if not defaults
       * back to returning the value of that.defaultZipRegEx in this file
       *
       * @return {object} RegExp object
       */
      getLocalisedZipRegEx: function () {
        try {
          return new RegExp(DEFAULTS.ZIP_REGEX, 'i');
        } catch (e) {
          // Swallow any invalid regular expression pattern errors for now
        }
      },

      openOverlay: function (args) {
        var _content = args.content;
        var _onComplete = args.onComplete;
        var _onClosed = args.onClosed;
        var $window = $(window);
        var windowWidth = $window.width();
        var windowHeight = $window.height();
        var width = !that.isMobileView
          ? '997px'
          : windowWidth > 1300
            ? '1100px'
            : windowWidth < 1024
              ? '100%'
              : '980px';
        var height = that.isMobileView ? '100%' : args.height || 436;

        generic.overlay.launch({
          content: _content,
          includeBackground: true,
          includeCloseLink: true,
          initialHeight: height,
          className: 'bopis-overlay',
          height: height,
          override_mobile_width_height: 1,
          innerHeight: height,
          width: width,
          fixed: that.isMobileView ? true : false,
          onClosed: _onClosed,
          onComplete: function () {
            $(window).colorbox.resize({ height: height });
            $.isFunction(_onComplete) && _onComplete();
          }
        });
      },

      /**
       * Get an Invis template
       * returns: unprocessed template
       **/
      getTemplate: function (templateName) {
        if (!templateCache[templateName]) {
          templateCache[templateName] = bopisTemplates
            .find('script.inline-template[path="' + templateName + '"]')
            .html();
        }

        return templateCache[templateName];
      },

      /**
       * Get an Invis template and processes it with data
       * returns: html (processed template)
       **/
      renderTemplate: function (templateName, data) {
        var tmpl = that.getTemplate(templateName);
        var rendered = data ? Mustache.render(tmpl, data) : tmpl;

        return rendered;
      },

      /**
       * Render a bopis teamplate with translations
       * returns: html (processed template)
       **/
      renderBopisTemplate: function (templateName, data) {
        var translations = (site.templates.bopis_v1 && site.templates.bopis_v1.data) || {};

        data = data || {};
        $.extend(data, translations);

        return this.renderTemplate(templateName, data);
      },

      getGoogleMapsHref: function (door) {
        var fields = [
          'ADDRESS',
          'ADDRESS2',
          'CITY',
          'STATE_OR_PROVINCE',
          'COUNTRY',
          'ZIP_OR_POSTAL'
        ];
        // Adding a comma after door name to help Google parse query string into viable address
        var queryStr = door['DOORNAME'] ? door['DOORNAME'] + ',' : '';

        _.each(fields, function (f) {
          queryStr += door[f] ? door[f] + ' ' : '';
        });

        return 'http://maps.google.com/maps?q=' + encodeURIComponent(queryStr);
      },

      hoursToAm: function (nHour) {
        var hour = nHour.length > 3 ? nHour.slice(0, 2) : nHour.slice(0, 1);
        var min = nHour.slice(-2);
        var date = new Date();
        var lang = $('html').attr('lang');
        var hourFormat = !Drupal.settings.globals_variables.bopis_24hours_format;

        date.setHours(hour);
        date.setMinutes(min);

        return date
          .toLocaleString(lang || 'default', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: hourFormat
          })
          .toLowerCase();
      },

      weekDayFromIndex: function (dayIndex) {
        var date = new Date();
        var currentDay = date.getDay();
        var distance = dayIndex - currentDay;
        var lang = $('html').attr('lang');

        date.setDate(date.getDate() + distance);

        return date.toLocaleString(lang || 'default', { weekday: 'long' });
      },

      generateOpenHours: function (store_hours, date) {
        var hours = store_hours;
        var todayHours = hours && hours.raw[date.day];
        var toAM = function (nRawHour) {
          var nHour = nRawHour / 100;
          var rHour = nHour % 12;

          if (!Number.isInteger(rHour)) {
            rHour = rHour.toFixed(2);
          }

          return (rHour > 0 ? rHour : 12) + (nHour / 12 > 1 ? 'PM' : 'AM');
        };

        if (!hours || !todayHours) {
          return '';
        }

        return toAM(parseInt(todayHours.open)) + ' - ' + toAM(parseInt(todayHours.close));
      },

      renderOpenCloseStoreHoursRange: function (storeHours) {
        if (storeHours.is_closed || (storeHours.close === '0000' && storeHours.open === '0000')) {
          return bopisTranslations.bopis.store_closed;
        }

        return this.hoursToAm(storeHours.open) + ' - ' + this.hoursToAm(storeHours.close);
      },

      generateOpenHoursWeek: function (store_hours) {
        var self = this;
        var hoursRaw = (store_hours && store_hours.raw) || {};
        var hoursList = '';
        var hoursKeys = Object.keys(hoursRaw);

        if (hoursKeys.length) {
          hoursKeys.forEach(function (key) {
            var listString =
              '<li>' +
              '<span class="invis-result__week-list--item">' +
              self.weekDayFromIndex(key) +
              '</span>' +
              ' ' +
              self.renderOpenCloseStoreHoursRange(hoursRaw[key]) +
              '</li>';

            hoursList += listString;
          });
        }

        return hoursList;
      },

      getPickupMessage: function (store_hours) {
        var hours = store_hours;

        if (!hours) {
          return;
        }
        var d = new Date();
        var today = d.getDay();
        var todayClose = String(hours.raw[today].close);

        if (hours) {
          var close = new Date();
          var close_hour = todayClose.substring(0, 2);
          var close_mins = todayClose.substring(2);
          var close_hour_am_pm = close_hour > 12 ? close_hour - 14 : close_hour - 2;

          if (close_mins !== '00') {
            close_hour_am_pm += ':' + close_mins;
          }
          close.setHours(close_hour);
          close.setMinutes(close_mins);
          var now = new Date();
          var time_left = (close - now) / (1000 * 60 * 60);

          if (time_left > 2) {
            return bopisTranslations.today;
          }
        }

        return bopisInventory.getInstance().isAvailableNextBusinessDay(store_hours)
          ? bopisTranslations.bopis.next_bussiness_day
          : bopisTranslations.tomorrow;
      }
    };

    return {
      init: function (bopisTranslations) {
        if (!IS_INITIALIZED) {
          bopisText = bopisTranslations;
          IS_INITIALIZED = true;
        }
      },
      getInstance: function () {
        return that;
      }
    };
  })();
  var bopis = (function ($) {
    var that;
    var DM = {
      STANDARD: 'standard',
      BOPIS: 'bopis',
      COURIER: 'courier'
    };
    var IS_INITIALIZED = false;
    var BOPIS_DROPOFF_OFFSET =
      (Drupal.settings.globals_variables.bopis_dropoff_offset &&
        parseInt(Drupal.settings.globals_variables.bopis_dropoff_offset)) ||
      2;
    var bopisLocation = null;
    var bopisLocationApi = null;
    var bopisCache = null;
    var bopisLocationCache = null;
    var bopisInventory = null;
    var twoHoursDelivery = null;
    var bopisUtils = null;
    var isBopisModalOpen = false;
    var selectedSkuId = null;
    var cartIds = [];
    var productSkus = [];
    var kitSkus = [];
    var inventory = {};
    var _isBopisInitialized = false;
    var $zipInput = $('.js-bopis-zip-input');
    var $replenishmentSelect = $('.js-replenishment-select');
    var $doorsList = null;
    var $bopisContainer = null;
    var $availabilityContainer;
    var map = null;
    var markers = [];
    var mapInfoWindow = null;
    var mapSelectedDoorId = null;
    var isReplenishmentActive = false;
    var bopisLimitedQty = Drupal.settings.globals_variables.max_limited_qty || -1;
    var pdpSelectionCookie = $.cookie('PDP_SELECTION');
    var bopisSelectPdp = pdpSelectionCookie || Drupal.settings.globals_variables.bopis_select_pdp;
    var selectedDM = DM.STANDARD;
    var newDMSelection;
    var activeDM;
    var isCourierAvailable = false;
    var isBopisAvailable = false;
    var availabilityObj = {
      standard: {
        isAvailable: false,
        message: ''
      },
      courier: {
        isAvailable: false,
        message: ''
      },
      bopis: {
        isAvailable: false,
        message: ''
      }
    };
    var $deliveryOptionsContainer;
    var $homeDelivery;
    var $courierDelivery;
    var $bopisDelivery;
    var bopisDoorID;
    var userDoorSelection = false;
    var transObj = {};
    var isBundle = $('.js-product-bundle').length > 0;
    var isKitItem = function () {
      return Array.isArray(kitSkus) && kitSkus.length;
    };
    /**
     * Returns the products skus used for inventory API. For kit items
     * we only use the selected skus to reduce the payload.
     * @return {array}
     */
    var getInvSkus = function () {
      return isKitItem() ? kitSkus : productSkus;
    };
    var getCurrentSkus = function () {
      return isKitItem() ? kitSkus : selectedSkuId ? [selectedSkuId] : [];
    };
    var hasDoorAvailability = function (door, includeCartSkus) {
      var skus = getCurrentSkus();
      var inv = door && door.door_inventory && door.door_inventory.skus_onhand;
      var cartInv = door && door.door_inventory && door.door_inventory.cart_skus_onhand;
      var available = true;
      skus.forEach(function (id) {
        available = available && inv && inv[id] && inv[id].is_available;
      });
      if (includeCartSkus) {
        cartIds.forEach(function (id) {
          available = available && cartInv && cartInv[id] && cartInv[id].is_available;
        });
      }
      return available;
    }
    var handleHomeDeliveryAvailability = function () {
      if (!selectedSkuId) {
        return false;
      }
      var $container = $('.js-bopis-container');
      var $standardItem = $('.js-delivery-item-standard', $container);
      var $standardItemNotice = $('.js-delivery-item-notice-standard', $container);

      skuData = prodcat.data.getSku(selectedSkuId);
      $standardItem.find('.loading-effect-active').loadingEffect('destroy');
      if ((!isBundle && skuData.isShoppable) || (isBundle && isBundleShoppable())) {
        $standardItem.removeClass('bopis__delivery-item-disabled');
        availabilityObj.standard = {
          message: bopisTranslations.bopis.spp_home_delivery_sub_title,
          isAvailable: true
        };
      } else {
        $standardItem.addClass('bopis__delivery-item-disabled');
        availabilityObj.standard = {
          message: bopisTranslations.bopis.spp_home_delivery_sub_title_disabled,
          isAvailable: false
        };
      }
      $standardItemNotice.html(availabilityObj.standard.message);
    };
    var isBundleShoppable = function () {
      var isShoppable = true;
      kitSkus.forEach(function (sku) {
        var skudata = prodcat.data.getSku(sku);
        isShoppable = isShoppable && skudata && !!skudata.isShoppable;
      });
      return isShoppable;
    };
    var updateAvailabilityContainer = function(deliveryMethod) {
      var message = availabilityObj[deliveryMethod] && availabilityObj[deliveryMethod].message;
      var isAvailable = availabilityObj[deliveryMethod] && availabilityObj[deliveryMethod].isAvailable;
      $availabilityContainer.html(message);
      $availabilityContainer[isAvailable ? "removeClass" : "addClass"]('not-available');
      $('.js-bopis-delivery-message-container .js-check-store', $deliveryOptionsContainer)[deliveryMethod === DM.BOPIS ? "removeClass" : "addClass"]('hidden');
    }
    var handleDeliveryItemsRender = function (inventory) {
      var door = bopisInventory.findBestDoor(cartIds.concat(getCurrentSkus()));
      var $container = $('.js-bopis-container');
      var $bopisItem = $('.js-delivery-item-bopis', $container);
      var $postmatesItem = $('.js-delivery-item-courier', $container);
      var $postmatesItemNotice = $('.js-delivery-item-notice-postmates', $container);
      var $bopisItemNotice = $('.js-delivery-item-notice-bopis', $container);
      var $deliveryItem = $('.js-delivery-item', $container);
      var $deliveryItemNotice = $('.js-delivery-item-notice', $container);
      var $quantityNotice = $('.js-bopis-quantity-notice', $container);
      var selectedDoor = userDoorSelection && bopisDoorID && bopisInventory.data.doors[bopisDoorID];
      var _handleIsNotShoppable = function () {
        var updatedDoorName = '';

        if (!door || (transObj && transObj.trans && transObj.trans.hasReplenishmentItem)) {
          $deliveryItem.addClass('bopis__delivery-item-disabled');
          $deliveryItemNotice.html(
            bopisTranslations.bopis.spp_same_day_delivery_sub_title_disabled
          );
        } else {
          updatedDoorName +=
            bopisTranslations.bopis.spp_home_delivery_sub_title +
            ' ' +
            bopisTranslations.bopis.preposition +
            ' ' +
            door.info.DOORNAME +
            (door.info.SUB_HEADING ? ' ' + door.info.SUB_HEADING : '');
          updatedDoorName += bopisTranslations.bopis.call_for_order
            ? ' <br /><img src="/media/export/cms/bopis/phone_icon.png" alt=""><span class="bopis-call-notice">' +
              bopisTranslations.bopis.call_for_order.replace(
                '::phone_number::',
                '<a href="tel:' + door.info.PHONE1 + '">' + door.info.PHONE1 + '</a>'
              ) +
              '</span>'
            : '';
          $bopisItem.removeClass('bopis__delivery-item-disabled');
          $bopisItemNotice.html(updatedDoorName);
          availabilityObj.bopis = {
            message: updatedDoorName,
            isAvailable: true
          };
        }
        $postmatesItem.addClass('bopis__delivery-item-disabled');
        availabilityObj.courier = {
          message: bopisTranslations.bopis.spp_same_day_delivery_sub_title_disabled,
          isAvailable: false
        };
        $postmatesItemNotice.html(availabilityObj.courier.message);
        $postmatesItem.find('.js-bopis-quantity-notice').hide();
      };

      $availabilityContainer = $('.js-selected-delivery-status', $container);
      isBopisAvailable = Boolean(door);
      isCourierAvailable = door && bopisInventory.checkBopisOrDeliveryAvailabilty(
        cartIds.concat(getCurrentSkus()),
        'courier_delivery',
        true
      );
      selectedDM = transObj && transObj.order && transObj.order.shipMethodName || DM.STANDARD;
      activeDM = newDMSelection || selectedDM;
      // Reset and recalculate the selected door id unless the customer selected the current door
      if (!userDoorSelection) {
        bopisDoorID = null;
      }
      if (bopisSelectPdp) {
        // default to standard delivery if BOPIS or courier are not available
        if ((activeDM === DM.COURIER && !isCourierAvailable)
            || (activeDM === DM.BOPIS && !isBopisAvailable)) {
          activeDM = DM.STANDARD;
        }
        $('.js-delivery-item', $deliveryOptionsContainer).removeClass('dm-active');
        $('.js-delivery-item-' + activeDM, $deliveryOptionsContainer).addClass('dm-active');
      }
      $container.find('.loading-effect-active').loadingEffect('destroy');
      $quantityNotice.hide();
      if (isReplenishmentActive) {
        availabilityObj.courier = {
          message: bopisTranslations.bopis.auto_replenishment_not_available_message,
          isAvailable: false
        };
        availabilityObj.bopis = availabilityObj.courier;
        $postmatesItemNotice.html(availabilityObj.courier.message);
        $bopisItemNotice.html(bopisTranslations.bopis.auto_replenishment_not_available_message);
        $deliveryItem.filter(':not(.js-delivery-item-standard)').addClass('bopis__delivery-item-disabled');
        updateAvailabilityContainer(activeDM);
        return;
      }

      if ((isBundle && !isBundleShoppable()) || !skuData.isShoppable) {
        _handleIsNotShoppable();
        updateAvailabilityContainer(activeDM);

        return false;
      }

      if (!bopisDoorID) {
        bopisDoorID = bopisInventory.data && bopisInventory.data.bopis_store_number;
      }

      if (!selectedDoor) {
        selectedDoor = bopisDoorID && bopisInventory.data.doors[bopisDoorID];
      }

      if (selectedDoor && hasDoorAvailability(selectedDoor, true)) {
        door = selectedDoor;
      } else if (door) {
        bopisDoorID = door.info.DOOR_ID;
      } else {
        bopisDoorID = null;
      }

      if (!door) {
        $bopisItem.addClass('bopis__delivery-item-disabled');
        $postmatesItem.addClass('bopis__delivery-item-disabled');
        availabilityObj.courier = {
          message: bopisTranslations.bopis.spp_same_day_delivery_sub_title_disabled,
          isAvailable: false
        };
        availabilityObj.bopis = availabilityObj.courier;
        $deliveryItemNotice.html(bopisTranslations.bopis.spp_same_day_delivery_sub_title_disabled);
        updateAvailabilityContainer(activeDM);
        return;
      }

      var doorInfo = door.info;
      var storeHours = door.store_hours;
      var doorName = doorInfo.DOORNAME;
      var doorSubHeading = doorInfo.SUB_HEADING;
      var updatedDoorName = doorName + (doorSubHeading ? ' ' + doorSubHeading : '');
      var isDeliveryToday = bopisInventory.canDeliverToday(storeHours, BOPIS_DROPOFF_OFFSET);
      var deliveryDay = isDeliveryToday
        ? bopisTranslations.today
        : bopisInventory.isAvailableNextBusinessDay(storeHours)
          ? bopisTranslations.bopis.next_bussiness_day
          : bopisTranslations.tomorrow;

      updatedDoorName =
        bopisTranslations.bopis.spp_home_delivery_sub_title +
        ' ' +
        deliveryDay +
        ' ' +
        bopisTranslations.preposition +
        '<span class="bopis-shop-name"> ' +
        updatedDoorName +
        '</span>';

      $bopisItem.removeClass('bopis__delivery-item-disabled');
      $bopisItemNotice.html(updatedDoorName);
      availabilityObj.bopis = {
        message: updatedDoorName,
        isAvailable: true
      };

      var limitedQuantityCheck = function (limit) {
        var inv = door.door_inventory.skus_onhand[selectedSkuId];
        var isLimited = inv['is_available'] && inv.available_qty > 0 && inv.available_qty <= limit;

        return isLimited ? inv.available_qty : false;
      };

      if (
        door.door_inventory &&
        door.door_inventory.skus_onhand &&
        door.door_inventory.skus_onhand[selectedSkuId]
      ) {
        var bopisQuantityCheck = limitedQuantityCheck(bopisLimitedQty);

        if (bopisQuantityCheck) {
          var quantityNotice = bopisTranslations.bopis.spp_quantity_notice;
          var noticeText = quantityNotice
            ? quantityNotice.replace('%quantity%', bopisQuantityCheck)
            : bopisQuantityCheck;

          $quantityNotice.html(noticeText);
          $quantityNotice.show();
        }
      }

      var nearestStore = twoHoursDelivery.getNearestStoreAvailable(
        cartIds.concat(getCurrentSkus()),
        bopisCache.getSSData(bopisInventory.getLastRequestParams())
      );

      if (!skuData.isShoppable || !isCourierAvailable || !nearestStore) {
        $postmatesItem.addClass('bopis__delivery-item-disabled');
        availabilityObj.courier = {
          message: bopisTranslations.bopis.spp_same_day_delivery_sub_title_disabled,
          isAvailable: false
        };
        $postmatesItem.find('.js-bopis-quantity-notice').hide();
      } else {
        $postmatesItem.removeClass('bopis__delivery-item-disabled');
        var cutOffTime = twoHoursDelivery.getTodayDeliveryCutOffTime(storeHours);
        var canDeliverToday = twoHoursDelivery.canDeliverToday(storeHours);
        var deliveryDayLabel = canDeliverToday
          ? bopisTranslations.bopis.spp_same_day_delivery_sub_title + ' ' + cutOffTime
          : bopisInventory.isAvailableNextBusinessDay(storeHours)
            ? bopisTranslations.bopis.spp_home_delivery_sub_title +
            ' ' +
            bopisTranslations.bopis.next_bussiness_day
            : bopisTranslations.bopis.spp_home_delivery_sub_title + ' ' + bopisTranslations.tomorrow;

        availabilityObj.courier = {
          message: deliveryDayLabel,
          isAvailable: true
        };
      }
      $postmatesItemNotice.html(availabilityObj.courier.message);
      updateAvailabilityContainer(activeDM);
    };
    var handleReplenishmentCheck = function () {
      var $deliveryItem = $('.js-delivery-item:not(.js-delivery-item-standard)');
      var $postmatesItemNotice = $('.js-delivery-item-notice-postmates');
      var $bopisItemNotice = $('.js-delivery-item-notice-bopis');
      var replenishmentValue = $replenishmentSelect[0].value;
      var zip = bopisLocationCache.getUserZipCode();

      if (replenishmentValue !== '0') {
        availabilityObj.courier = {
          message: bopisTranslations.bopis.auto_replenishment_not_available_message,
          isAvailable: false
        };
        availabilityObj.bopis = {
          message: bopisTranslations.bopis.auto_replenishment_not_available_message,
          isAvailable: false
        };
        $postmatesItemNotice.html(availabilityObj.courier.message);
        $bopisItemNotice.html(bopisTranslations.bopis.auto_replenishment_not_available_message);
        $deliveryItem.addClass('bopis__delivery-item-disabled');
        updateAvailabilityContainer(newDMSelection || selectedDM);
        isReplenishmentActive = true;
      } else if (zip && selectedSkuId) {
        isReplenishmentActive = false;
        handleDeliveryItemsRender(inventory);
        updateAvailabilityContainer(activeDM);
      }
    };
    var showMoreInfoModal = function (e) {
      e.preventDefault();
      var panelHtml = bopisUtils.renderTemplate('invis/overlay');

      bopisUtils.openOverlay({
        content: panelHtml,
        height: '463px'
      });
    };
    var postmatesShowMoreInfoModal = function (e) {
      e.preventDefault();
      var panelHtml = bopisUtils.renderTemplate('postmatesinfo/overlay');

      bopisUtils.openOverlay({
        content: panelHtml
      });
    };
    var showBopisOverlay = function () {
      var bopisDoors = bopisInventory.getDoorsInfo();
      var isDoorsAvailable = bopisDoors && bopisDoors.doors && bopisDoors.doors.length;
      var overlayHeight = isDoorsAvailable ? '607px' : '330px';
      var panelHtml = bopisUtils.renderTemplate('invis/search_panel', { sku: selectedSkuId });
      var onCompleteFn = function () {
        $(document).trigger('bopis:mainPanel:rendered');
        isBopisModalOpen = true;
      };
      var onClosedFn = function () {
        $zipInput = $('.js-bopis-zip-input');
        isBopisModalOpen = false;
        $(document).trigger('invis:mainPanel:closed');
      };

      bopisUtils.openOverlay({
        content: panelHtml,
        onComplete: onCompleteFn,
        height: overlayHeight,
        onClosed: onClosedFn
      });
    };
    var handleOpenBopisOverlay = function (e) {
      var params = bopisInventory.getLastRequestParams();

      e && e.preventDefault();
      bopisInventory.getInventoryByDoors(params).then(function () {
        showBopisOverlay();
      });
    };
    var getTransaction = function () {
      var payload = [
        {
          method: 'trans.get',
          params: [
            {
              order_fields: ['items', 'shipMethodName'],
              trans_fields: ['TRANS_ID', 'hasReplenishmentItem']
            }
          ]
        }
      ];

      $.ajax({
        url: '/rpc/jsonrpc.tmpl?dbgmethod=trans.get',
        type: 'POST',
        async: false,
        data: $.param({ JSONRPC: JSON.stringify(payload) }),
        success: function (r) {
          transObj = r && r[0] && r[0].result && r[0].result.value;
          cartIds = transObj && transObj.order && transObj.order.items.map(function (item) {
            return item['sku.SKU_BASE_ID'];
          }) || [];
        }
      });
    };
    var _onAfterDMUpdate = function () {
      var params = bopisInventory.getLastRequestParams();

      bopisCache.clearSSStorage();
      getTransaction();
      bopisInventory.getInventoryByDoors(params);
      if (isReplenishmentActive && bopisSelectPdp) {
        $('.js-delivery-item').removeClass('dm-active');
        $('.js-delivery-item-standard').addClass('dm-active');
      }
    };
    var setStandardDelivery = function () {
      generic.jsonrpc.fetch({
        method: 'bopis.select_bopis',
        params: [
          {
            method: 'post',
            args: {
              mode: 'unset'
            }
          }
        ],
        onSuccess: function () {
          _onAfterDMUpdate();
        },
        onFailure: function () {
          _onAfterDMUpdate();
        }
      });
    };
    var setCourierDelivery = function () {
      generic.jsonrpc.fetch({
        method: 'courier.set_courier_ship_method',
        params: [
          {
            method: 'post',
            args: {
              mode: 'set'
            }
          }
        ],
        onSuccess: function () {
          _onAfterDMUpdate();
        },
        onFailure: function () {
          _onAfterDMUpdate();
        }
      });
    };
    var setBopisDelivery = function () {
      var door;
      var savedDoor = bopisInventory.data && bopisInventory.data.bopis_store_number;

      if (!bopisDoorID) {
        door = bopisInventory.findBestDoor(cartIds.concat(getCurrentSkus()));
        if (!(door && door.info && door.info.DOOR_ID)) {
          return false;
        }
        bopisDoorID = door.info.DOOR_ID;
      }

      if (!userDoorSelection && savedDoor && parseInt(savedDoor) !== parseInt(bopisDoorID)) {
        sessionStorage.setItem('bopis-store-changed', 1);
      }

      generic.jsonrpc.fetch({
        method: 'bopis.select_bopis',
        params: [
          {
            method: 'post',
            args: {
              mode: 'set',
              door_id: bopisDoorID
            }
          }
        ],
        onSuccess: function () {
          _onAfterDMUpdate();
        },
        onFailure: function (jsonRpcResponse) {
          var v = jsonRpcResponse.getValue();

          _onAfterDMUpdate();
          console.log(v);
        }
      });
    };
    var onAddToCartSuccess = function () {
      var dm = newDMSelection;

      dm = dm || selectedDM;
      if (!dm) {
        return false;
      }
      // Do not allow BOPIS or SDD for auto replenisment orders.
      if (isReplenishmentActive) {
        dm = DM.STANDARD;
      }
      if (dm === DM.COURIER && !isCourierAvailable) {
        dm = DM.STANDARD;
      }
      if (dm === DM.BOPIS && !isBopisAvailable) {
        dm = DM.STANDARD;
      }

      if (dm !== DM.STANDARD) {
        switch (dm) {
          case DM.BOPIS:
            setBopisDelivery();
            break;
          case DM.COURIER:
            setCourierDelivery();
            break;
          default:
            setStandardDelivery();
        }
      }
    };
    var selectBopisDoor = function (e) {
      var elem = $(e.target);
      var doorId = elem.data('door-id');

      userDoorSelection = true;
      bopisDoorID = doorId;
      $.colorbox && $.colorbox.close && $.colorbox.close();
      handleDeliveryItemsRender();
    };
    var selectStandardDelivery = function () {
      newDMSelection = DM.STANDARD;
      $('.js-delivery-item', $deliveryOptionsContainer).removeClass('dm-active');
      $homeDelivery.addClass('dm-active');
      updateAvailabilityContainer('standard');
    };
    var selectBopisDelivery = function () {
      newDMSelection = DM.BOPIS;
      $('.js-delivery-item', $deliveryOptionsContainer).removeClass('dm-active');
      $bopisDelivery.addClass('dm-active');
      updateAvailabilityContainer('bopis');
    };
    var selectCourierDelivery = function () {
      newDMSelection = DM.COURIER;
      $('.js-delivery-item', $deliveryOptionsContainer).removeClass('dm-active');
      $courierDelivery.addClass('dm-active');
      updateAvailabilityContainer('courier');
    };
    var onSelectPDPActive = function () {
      getTransaction();
      $(document).on('addToCart.success', onAddToCartSuccess.bind(this));
    };
    var activatePDPSelection = function () {
      bopisSelectPdp = true;
      $('.js-bopis-container').addClass('selection-on-pdp');
      onSelectPDPActive();
      handleDeliveryItemsRender();
      that.initDeliverySelection();
      if (!$.cookie('PDP_SELECTION')) {
        $.cookie('PDP_SELECTION', 1, { path: '/' });
      }
    };
    var renderSearchPanel = function (args) {
      var $modal = $('#cboxLoadedContent');

      $modal.find('.js-invis-results').remove();
      $modal.find('.js-invis-product').remove();
      $modal.find('.js-invis-form-container').remove();
      $modal.find('.bopis-search-mobile.js-invis-form-container').remove();
      if (!args) {
        $modal.find('.js-input-zip').val('');
      }
    };
    /* Render product info block with Add To Bag button */
    var renderProductInfo = function () {
      var productInfoHtml = bopisUtils.renderTemplate('invis/product');
      var $container = $('.js-bopis-product-info');

      $container.append(productInfoHtml);
      $('.js-add-to-cart', $container).data('sku-base-id', selectedSkuId);
    };
    /* Fetches template for search results container, renders & inserts into DOM */
    var renderResults = function (bopisDoors) {
      var zipCode = bopisLocationCache.getUserZipCode();
      var resultsHtml = bopisUtils.renderTemplate('/invis/search/results', {
        doorsCount: bopisDoors.doors.length,
        zipCode: zipCode
      });
      var $formContainer = $('.js-invis-search-container');

      $formContainer.append(resultsHtml);
      $zipInput = bopisUtils.isMobileView
        ? $('.js-bopis-zip-form-mobile-modal .js-bopis-zip-input')
        : $('.js-bopis-zip-form-modal .js-bopis-zip-input');
      bopisLocation.bindUserZipForm();
      bindMapTogglers();
      bopisLocation.bindUserZipForm($formContainer);
    };
    var renderMap = function (mapApi, bopisDoors) {
      if (!bopisDoors.doors || !bopisDoors.doors.length || !mapApi.maps) {
        return null;
      }

      var zoomLevel = 5;
      var mapOptions = {
        zoom: zoomLevel,
        center: new mapApi.maps.LatLng(
          Number(bopisDoors.info[bopisDoors.doors[0]].info['LATITUDE']),
          Number(bopisDoors.info[bopisDoors.doors[0]].info['LONGITUDE'])
        ),
        mapTypeId: mapApi.maps.MapTypeId.ROADMAP,
        disableDefaultUI: false,
        navigationControl: true,
        mapTypeControl: false,
        scaleControl: true,
        clickableIcons: false,
        styles: [
          {
            featureType: 'landscape',
            stylers: [{ saturation: -100 }, { lightness: 65 }, { visibility: 'on' }]
          },
          {
            featureType: 'poi',
            stylers: [{ saturation: -100 }, { lightness: 51 }, { visibility: 'simplified' }]
          },
          {
            featureType: 'road.highway',
            stylers: [{ saturation: -100 }, { visibility: 'simplified' }]
          },
          {
            featureType: 'road.arterial',
            stylers: [{ saturation: -100 }, { lightness: 30 }, { visibility: 'on' }]
          },
          {
            featureType: 'road.local',
            stylers: [{ saturation: -100 }, { lightness: 40 }, { visibility: 'on' }]
          },
          {
            featureType: 'transit',
            stylers: [{ saturation: -100 }, { visibility: 'simplified' }]
          },
          {
            featureType: 'administrative.province',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'water',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }, { lightness: -25 }, { saturation: -100 }]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ hue: '#c5d6f0' }, { lightness: -25 }, { saturation: -97 }]
          }
        ]
      };

      map = new mapApi.maps.Map($('.js-map-container')[0], mapOptions);
      setTimeout(function () {
        $(window).width() < 767 && $('.js-map-container').hide();
      }, 1500);
    };
    var renderError = function () {
      var errorHtml = bopisUtils.renderTemplate('/invis/error');
      var radius = bopisInventory.DEFAULTS.RADIUS;
      var $invisSearchContainer = $('.js-invis-search-container');

      $invisSearchContainer
        .append(errorHtml.replace('%radius%', radius))
        .find('.bopis-popup-block--mobile')
        .addClass('hidden');
    };
    var bindMapTogglers = function () {
      var $mapActive = $('.js-bopis-map-active');
      var $listActive = $('.js-bopis-list-active');
      var $resultsContainer = $('.js-stores-container');
      var $mapContainer = $('.js-map-container');

      $mapActive.on('click', function (e) {
        $listActive.removeClass('active');
        $mapActive.addClass('active');
        $resultsContainer.hide();
        $mapContainer.show();
      });
      $listActive.on('click', function (e) {
        $resultsContainer.show();
        $mapContainer.hide();
        $mapActive.removeClass('active');
        $listActive.addClass('active');
      });
    };
    var displayInfoWindow = function (doorId) {
      var door;
      var marker;
      var doorPhone;
      var doorDirection;
      var phoneContent;
      var directionContent;
      var bopisDoors = bopisInventory.getDoorsInfo();

      if (mapInfoWindow) {
        mapInfoWindow.close && mapInfoWindow.close();
      }
      door = bopisDoors.info[doorId];
      if (!door || !door.info) {
        return false;
      }
      marker = markers.find((item) => item.id === doorId);
      doorPhone = door.info.PHONE1;
      doorDirection = bopisUtils.getGoogleMapsHref(door.info);
      phoneContent = doorPhone
        ? '<div class="tooltip-phone"><a href="tel:' + doorPhone + '">' + doorPhone + '</a></div>'
        : '';
      directionContent = doorDirection
        ? '<div class="tooltip-direction"><a target="_blank" href="' +
          doorDirection +
          '">' +
          bopisTranslations.bopis.view_direction +
          '</a></div>'
        : '';

      mapInfoWindow = new google.maps.InfoWindow({
        content:
          '<div class="tooltip-doorname">' +
          door.info.DOORNAME +
          '</div><div>' +
          door.info.ADDRESS +
          '</div>' +
          phoneContent +
          directionContent
      });
      mapInfoWindow.open(that.map, marker);
      mapSelectedDoorId = doorId;
      map.setCenter(marker.getPosition());
      google.maps.event.addListener(mapInfoWindow, 'domready', function () {
        var iwOuter = $('.gm-style-iw');
        var iwCloseBtn = iwOuter.find('.gm-ui-hover-effect');

        iwCloseBtn
          .css({
            opacity: '1',
            color: '#fff',
            top: '0',
            right: '0'
          })
          .html('X');
        iwCloseBtn.mouseout(function () {
          $(this).css({ opacity: '1' });
        });
      });
    };
    var selectDoorInList = function (doorId) {
      var $selectedLi;

      $doorsList.find('li').removeClass('selected');
      $selectedLi = $doorsList.find('li[data-door-id=' + doorId + ']');

      if ($selectedLi.length) {
        $selectedLi.addClass('selected');
        $doorsList.animate({
          scrollTop: $selectedLi.offset().top - $doorsList.offset().top + $doorsList.scrollTop()
        });
      }
    };
    var handleStoreClick = function (doorId) {
      displayInfoWindow(doorId);
      selectDoorInList(doorId);
    };
    var renderStores = function (mapApi, bopisDoors) {
      var createMarkers;
      var limitedDoors;
      var bounds;
      var zip;
      var storesDistance;
      var doorsHtml;
      var cache;
      var userCoords;
      var userMarkerPosition;

      if (!bopisDoors.doors || !bopisDoors.doors.length || !mapApi.maps) {
        return null;
      }
      createMarkers = function (doorId, idx) {
        var door = bopisDoors.info[doorId];
        var doorInventory = door.door_inventory;
        var cartInv = doorInventory && doorInventory.cart_skus_onhand;
        var skusInv = doorInventory && doorInventory.skus_onhand;
        var bopisSelectedStore = bopisInventory && bopisInventory.data 
          && bopisInventory.data.bopis_store_number;
        var allCartAvailable = 1;
        var currentSkus = getCurrentSkus();

        door.info.idx = idx;
        door.info.googleMapsHref = bopisUtils.getGoogleMapsHref(door.info);
        door.info.openHoursWeek = bopisUtils.generateOpenHoursWeek(door.store_hours);
        door.info.pickupText = bopisUtils.getPickupMessage(door.store_hours);
        door.info.hasInventory = 0;
        door.info.hasAvailable = 0;
        door.info.isCall = 0;
        door.info.statusClass = 'no-inventory';
        door.info.shortDistance = door.info.DISTANCE
          ? parseFloat(door.info.DISTANCE.match(/\d*.\d/)[0])
          : parseFloat(storesDistance[doorId].match(/\d*.\d/)[0]);
        if (currentSkus) {
          door.info.hasInventory = 1;
          door.info.statusClass = 'has-inventory';
          if (hasDoorAvailability(door)) {
            door.info.statusClass = 'has-available';
            door.info.hasAvailable = 1;
          }
          if (!isKitItem() && selectedSkuId && skusInv[selectedSkuId] && skusInv[selectedSkuId].is_call) {
            door.info.isCall = 1;
            door.info.statusClass += ' call-for-availability';
          }
        }

        door.info.selectedDoor = door.info.DOOR_ID === (bopisDoorID + '' || bopisSelectedStore) ? 1 : 0;
        door.info.bopisSelectPdp = bopisSelectPdp;

        if (cartInv) {
          cartIds.forEach(function (id) {
            allCartAvailable = allCartAvailable && cartInv[id] && cartInv[id].is_available;
          });
          door.info.allCartAvailable = allCartAvailable;
        }
        door.info.allCartAvailable = allCartAvailable && door.info.hasAvailable;

        doorsHtml += bopisUtils.renderTemplate('/invis/search/door', door.info);
        if (map) {
          var latLng = new mapApi.maps.LatLng(door.info.LATITUDE, door.info.LONGITUDE);

          bounds.extend(latLng);
          var marker = new mapApi.maps.Marker({
            position: latLng,
            map: map,
            icon: {
              url: '/media/export/cms/bopis/store-pin.png',
              scaledSize: new mapApi.maps.Size(48, 48),
              origin: new mapApi.maps.Point(0, 0),
              anchor: new mapApi.maps.Point(0, 48)
            },
            optimized: false,
            index: idx,
            id: Number(door.info.DOOR_ID),
            title: String(door.info.DOOR_ID)
          });

          map.fitBounds(bounds);

          marker.addListener('click', function (e) {
            handleStoreClick(marker.id);
          });

          markers.push(marker);
        }
      };
      limitedDoors =
        bopisDoors.doors.length > 10 ? bopisDoors.doors.slice(0, 10) : bopisDoors.doors;
      bounds = new mapApi.maps.LatLngBounds();
      zip = bopisLocationCache.getUserZipCode();
      storesDistance = bopisCache.getStoresDistance({
        zip: zip,
        radius: bopisInventory.DEFAULTS.RADIUS
      });
      doorsHtml = '';
      // Render user marker to the stores map
      cache = bopisCache.getSSData(bopisInventory.getLastRequestParams());
      userCoords = cache &&
        cache.inventory && {
        lat: cache.inventory.latitude,
        lng: cache.inventory.longitude
      };

      if (userCoords) {
        userMarkerPosition = new mapApi.maps.LatLng(userCoords.lat, userCoords.lng);

        bounds.extend(userMarkerPosition);
        map.fitBounds(bounds);

        /* eslint-disable no-new */
        new mapApi.maps.Marker({
          position: userMarkerPosition,
          map: map,
          icon: {
            url: '/media/export/cms/bopis/user-pin.png',
            scaledSize: new mapApi.maps.Size(70, 70),
            origin: new mapApi.maps.Point(0, 0),
            anchor: new mapApi.maps.Point(0, 70)
          },
          optimized: false,
          title: 'Your location'
        });
      }
      markers = [];
      limitedDoors.forEach(createMarkers);
      $doorsList = $('ul.js-results-list');
      $doorsList.append(doorsHtml);
      $doorsList.find('.js-door-list-item').on('click', function (e) {
        var id = $(this).data('door-id');

        handleStoreClick(id);
      });

      $('.js-select-bopis-door').on('click', selectBopisDoor);

      $('#bopis-all-available').on('change', function () {
        var $nonAvailable = $('ul.js-results-list > li:not(.has-available)');
        var allAvailableChecked = $(this).is(':checked');
        var allBopisDoors = Object.keys(bopisInventory.data.doors);
        var availableDoors = bopisInventory.getAllAvailableDoorIDs(selectedSkuId);
        var doorCount = allAvailableChecked ? availableDoors.length : allBopisDoors.length;
        var doors = allAvailableChecked ? availableDoors : allBopisDoors;
        var selectedMarker;

        if (allBopisDoors.length === availableDoors.length) {
          return;
        }

        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }

        markers = [];

        doors.forEach(createMarkers);
        if (allAvailableChecked) {
          $nonAvailable.hide();
        } else {
          $nonAvailable.show();
        }

        $('.js-store-count').text(doorCount);
        selectedMarker = mapSelectedDoorId && markers && markers.find((item) => item.id === mapSelectedDoorId);
        if (map && mapInfoWindow && selectedMarker) {
          mapInfoWindow.open && mapInfoWindow.open(map, selectedMarker);
        }
      });

      $doorsList.find('.js-bopis-hours-accordion').on('click', function (e) {
        var $this = $(this);

        $this.toggleClass('active');
        $this.find('.invis-result__week-list').slideToggle(200);
      });

      // Bind event to doors list items
    };
    var renderNoDoorsPopup = function () {
      var zipCode = bopisLocationCache.getUserZipCode();
      var resultsHtml = bopisUtils.renderTemplate('/invis/search/no_results', { zipCode: zipCode });
      var $formContainer = $('.js-invis-search-container');

      $('.js-invis-form-container').remove();
      $formContainer.append(resultsHtml);
      bopisLocation.bindUserZipForm($formContainer);
    };

    if (!bopis_enabled) {
      return false;
    }

    $(document).on('product.skuSelect', '.js-product-full', function (e, skuId) {
      if (_isBopisInitialized && !isBundle) {
        var userZip = bopisLocationCache.getUserZipCode();

        selectedSkuId = skuId;
        handleHomeDeliveryAvailability();

        bopisInventory
          .getInventoryByDoors({
            skus: getInvSkus(),
            zip: userZip
          })
          .then(handleDeliveryItemsRender)
          .catch(function (error) {
            console.log(error);
          });
      }
    });

    // eslint-disable-next-line no-unused-vars
    $('.js-product-bundle').on('product.bundleSelect', function (e, sSkus) {
      var userZip = bopisLocationCache.getUserZipCode();
      var invSkus;

      if (!sSkus) {
        return false;
      }
      kitSkus = sSkus.replaceAll(' ', '').split(',');
      invSkus = kitSkus.slice();
      handleHomeDeliveryAvailability();
      bopisInventory.getInventoryByDoors({
        skus: invSkus,
        zip: userZip
      })
        .then(function (data) {
          // Check if the skus has changed since the request started.
          if (JSON.stringify(invSkus) !== JSON.stringify(kitSkus)) {
            return false;
          }
          handleDeliveryItemsRender();
          $(document).trigger(bopisLocation.EVENTS.LocationZipCodeFallback, {
            userCoords: {
              latitude: data.latitude,
              longitude: data.longitude
            }
          });
        });
    });

    $(document).on('product.sppInit', '.js-product-ui', function () {
      var $product;
      var prod_id;
      var prodData;
      var prodSkus;

      if (_isBopisInitialized) {
        return false;
      }

      $product = $(this);
      prod_id = $product.attr('data-product-id');
      prodData = prodcat.data.getProduct(prod_id);
      prodSkus = prodData.skus.map(function (item) {
        return item.SKU_BASE_ID;
      });

      skuData = prodcat.data.getSku($product.attr('data-sku-base-id'));
      skuData && skuData.SKU_BASE_ID && site.Bopis && site.Bopis.initBopis 
        && site.Bopis.initBopis(skuData.SKU_BASE_ID, prodSkus);
    });

    $(document).on('bopis:mainPanel:rendered', function (e, args) {
      var bopisDoors = bopisInventory.getDoorsInfo();

      $bopisContainer = $('.bopis-overlay');
      renderSearchPanel(args);
      if (bopisDoors.doors && bopisDoors.doors.length) {
        renderResults(bopisDoors);
        renderProductInfo();
        bopisLocationApi.getMapApi().then(function (mapApi) {
          renderMap(mapApi, bopisDoors);
          renderStores(mapApi, bopisDoors);
        });
      } else {
        renderError();
        renderNoDoorsPopup();
      }
    });

    $(document).on('product.updateInvStatus', '.js-product-ui', function () {
      handleHomeDeliveryAvailability();
    });

    if (bopisSelectPdp && !pdpSelectionCookie) {
      onSelectPDPActive();
    }

    $replenishmentSelect.on('change', handleReplenishmentCheck);

    that = {
      getIsBipisInitialized: function () {
        return _isBopisInitialized;
      },
      handleOpenBopisOverlay: handleOpenBopisOverlay,
      showMoreInfoModal: showMoreInfoModal,
      bopisSelectPdp: bopisSelectPdp,
      activatePDPSelection: activatePDPSelection,
      inventorySkus: function() {
        return cartIds.concat(getCurrentSkus())
      },

      bindBopisOverlay: function () {
        var $deliveryOptionsContainer = $('.js-bopis-container');
        var $bopisStoresModal = $('.js-check-store', $deliveryOptionsContainer);
        var $bopisInfoModal = $('.js-bopis-more-info', $deliveryOptionsContainer);
        var $postmatesInfoModal = $('.js-courier-more-info', $deliveryOptionsContainer);

        $bopisStoresModal.once().click(handleOpenBopisOverlay);
        $bopisInfoModal.once().click(showMoreInfoModal);
        $postmatesInfoModal.once().click(postmatesShowMoreInfoModal);
      },

      initDeliverySelection: function() {
        $deliveryOptionsContainer = $('.js-bopis-container');
        $homeDelivery = $('.js-delivery-item-standard', $deliveryOptionsContainer);
        $courierDelivery = $('.js-delivery-item-courier', $deliveryOptionsContainer);
        $bopisDelivery = $('.js-delivery-item-bopis', $deliveryOptionsContainer);

        $homeDelivery.once().click(selectStandardDelivery);
        $courierDelivery.once().click(selectCourierDelivery);
        $bopisDelivery.once().click(selectBopisDelivery);
      },

      bindLocationZipCode: function () {
        $(document).off(bopisLocation.EVENTS.LocationZipCodeNew).on(bopisLocation.EVENTS.LocationZipCodeNew, function (e, args) {
          if (isBundle && !kitSkus.length) {
            return false;
          }
          bopisInventory
            .getInventoryByDoors({
              skus: getInvSkus(),
              zip: args.zip
            })
            .then (function () {
              bopisLocation.hideZipForm();
              handleDeliveryItemsRender();
              isBopisModalOpen && handleOpenBopisOverlay();
              site.track.trackBopisEvent({
                action: 'bopisSearch',
                payload: { event_label: 'click' }
              });
            });
        });

        $(document).on(bopisLocation.EVENTS.LocationZipCodeFail, function (e, params) {
          bopisInventory.getInventoryByDoors({ skus: getInvSkus() }).then(
            function (data) {
              $(document).trigger(bopisLocation.EVENTS.LocationZipCodeFallback, {
                userCoords: {
                  latitude: data.latitude,
                  longitude: data.longitude
                },
                skipCache: params.skipCache
              });
            },
            () => {}
          );
        });

        var renderUnavailableMessage = function () {
          var $container = $('.js-bopis-container');
          var $deliveryItems = $('.js-delivery-item', $container);

          if ($container.length && $deliveryItems && $deliveryItems.length) {
            $container.find('.loading-effect-active').loadingEffect('destroy');
            $deliveryItems.filter(':not(.js-delivery-item-standard)').addClass('bopis__delivery-item-disabled');
            $('.js-delivery-item-notice', $container).html(
              bopisTranslations.bopis.spp_same_day_delivery_sub_title_disabled
            );
          }
        };

        $(document).on('BopisInventory:query:failure', renderUnavailableMessage);

        $(document).on(bopisLocation.EVENTS.LocationZipCodeFallbackFail, function (e, params) {
          if ($bopisContainer && $bopisContainer.length) {
            $bopisContainer.find('.loading-effect-active').loadingEffect('destroy');
            $bopisContainer.find('.js-bopis-zip-form-modal').addClass('invalid-zip');
          } else {
            if (isBundle && !kitSkus.length) {
              return false;
            }
            bopisInventory.getInventoryByDoors({ skus: getInvSkus() }).then(
              function (data) {
                handleDeliveryItemsRender();
                $(document).trigger(bopisLocation.EVENTS.LocationZipCodeFallback, {
                  userCoords: {
                    latitude: data.latitude,
                    longitude: data.longitude
                  }
                });
              },
              () => {}
            );
          };
        });
      },

      initLoading: function () {
        $('.js-delivery-item-notice').loadingEffect({
          fontSize: 16,
          text: bopisTranslations.bopis.spp_delivery_options_loader
        });
        $('.js-delivery-item-notice-standard').loadingEffect({
          fontSize: 16,
          text: bopisTranslations.bopis.spp_delivery_options_loader
        });
      },

      initBopis(skuId, skus) {
        selectedSkuId = skuId;
        productSkus = skus;

        bopisUtils.insertTemplate();
        that.initLoading();
        bopisLocation.initLocation();
        that.bindLocationZipCode();
        bopisLocation.getUserZipCode();
        that.bindBopisOverlay();
        handleHomeDeliveryAvailability();
        if (bopisSelectPdp) {
          that.initDeliverySelection();
        }
        if (pdpSelectionCookie) {
          activatePDPSelection();
        }
        _isBopisInitialized = true;
      }
    };

    return {
      init: function (
        bopisLocationService,
        bopisLocationApiService,
        locationCacheService,
        BopisCacheService,
        bopisInventoryService,
        twoHoursDeliveryService,
        bopisUtilsService
      ) {
        if (!IS_INITIALIZED) {
          bopisLocation = bopisLocationService;
          bopisLocationApi = bopisLocationApiService;
          bopisLocationCache = locationCacheService;
          bopisCache = BopisCacheService;
          bopisInventory = bopisInventoryService;
          twoHoursDelivery = twoHoursDeliveryService;
          bopisUtils = bopisUtilsService;

          IS_INITIALIZED = true;
        }
      },
      getInstance: function () {
        return that;
      }
    };
  })(jQuery);

  if (bopis_enabled) {
    Model.BopisCache = bopisCache.getInstance();

    bopisUtils.init(bopisTranslations);
    Model.BopisUtils = bopisUtils.getInstance();

    bopisInventory.init(Model.BopisCache, Model.LocationApi.getInstance(), Model.BopisUtils);
    Model.BopisInventory = bopisInventory.getInstance();

    twoHoursDelivery.init(
      Model.BopisCache,
      Model.BopisUtils,
      bopisTranslations,
      Model.BopisInventory
    );
    Model.TwoHoursDelivery = twoHoursDelivery.getInstance();

    bopis.init(
      site.location_v1,
      Model.LocationApi.getInstance(),
      Model.LocationCache.getInstance(),
      Model.BopisCache,
      Model.BopisInventory,
      Model.TwoHoursDelivery,
      Model.BopisUtils
    );
    site.Bopis = bopis.getInstance();
  }
})();
